<script setup lang="ts">
import {useCartStore} from "~/stores/CartStore";

const {options} = useGlobalStore();
const {productSvgSource, product} = storeToRefs(useProductStore());
const router = useRouter();
const {showUpsalePopup, upsaleTrunk, showCart, cart, redirectToCheckout} = storeToRefs(useCartStore());
const {addToCart} = useCartStore();

const discountedPrice = computed(() => {
  if (!showUpsalePopup.value) return 0;
  const price = upsaleTrunk?.value?.discount?.discount;
  const type = upsaleTrunk?.value.discount?.discountType[0];

  if (type === 'precent') {
    return Math.floor(parseFloat(upsaleTrunk?.value?.trunk.price * (1 - (price / 100))));
  }
  return parseFloat(upsaleTrunk?.value?.trunk.price - price);
});
const carTitle = computed(() => {
  if (!showUpsalePopup.value) return '';
  return `${upsaleTrunk.value?.product?.makes.nodes[0].parent.node.name} ${upsaleTrunk.value?.product?.makes.nodes[0].name} ${carData.value.car_year} `;

});
const discountTitle = computed(() => {
  const title = options.tuPopupTitle2;
  const discountType = upsaleTrunk.value?.discount?.discountType[0] === 'precent' ? '%' : '₪';
  const discount = upsaleTrunk.value?.discount?.discount;
  return title.replace('%D%', `${discount}${discountType}`);
})
const productCategory = computed(() => {
  return upsaleTrunk.value?.product.productCategories.nodes[0].databaseId;
})
const carData = computed(() => {
  if (!showUpsalePopup.value) return {};
  const selected = JSON.parse(upsaleTrunk.value?.data[0]?.extraData);
  const allSelections = _CloneDeep(upsaleTrunk.value.product.productFields.selectionTypes);
  let full_option = {};
  allSelections.forEach((selection) => {
    if (selection.type[0] == 'trunk1') {
      full_option = {value: selection.type[0], label: selection.type[1]};
    }
  });
  selected.selected_options = 'trunk1';
  selected['trunk_discount'] = 1;
  try {
    selected.mat_color = (selected.mat_color);
    selected.mat_border_color = (selected.mat_border_color);
    selected.selected_meta = (selected.selected_meta);
  } catch (e) {
    console.log(e, selected);
  }
  upsaleTrunk.value.data[0].extraData = JSON.stringify(selected);
  selected.selected_options = full_option;
  return selected;
});

const submitAddToCart = async (isBuyNow = false) => {
  const products = upsaleTrunk.value.data;
  const rawProduct = {
    data: upsaleTrunk.value?.data[0],
    product: upsaleTrunk.value.product,
    quantity: upsaleTrunk.value?.data[0].quantity,
    price: discountedPrice.value,
    fullPrice: upsaleTrunk.value.trunk.price,
    rawData: carData.value
  }
  addToCart([rawProduct]);

  if (redirectToCheckout.value) {
    redirectToCheckout.value = false;
    router.push('/cart');
    showUpsalePopup.value = false;
    // upsaleTrunk.value = null;
  } else {
    showUpsalePopup.value = false;
    showUpsalePopup.value = false;
    showCart.value = true;
    // upsaleTrunk.value = null;
  }
  const data = await GqlAddToCartItems({
    input: {items: products},
  });
  cart.value = data.addCartItems?.cart;

};
const closeUpsalePop = () => {
  showUpsalePopup.value = false;
  if (redirectToCheckout.value) {
    redirectToCheckout.value = false;
    router.push('/cart');
    showUpsalePopup.value = false;
    // upsaleTrunk.value = null;
  }
  showCart.value = true;
}
</script>
<template>
  <v-dialog v-model="showUpsalePopup" class="trunk-upsale-modal" :max-width="650"
            :z-index="1000">
    <v-card class="upsale-content ">
      <a class="modal-close modal-close--absolute" href="javascript:void(0)" data-dismiss="modal" aria-label="Close">
        <nuxt-img src="/images/svg/close.svg" alt="אייקון של X לסימון סגירה חלון באתר"/>
      </a>
      <div class="modal-body p-4">
        <div class="title-h4 text-center">{{ options.tuPopupTitle1 || '' }}</div>
        <div class="title-h3 text-center">{{ discountTitle || '' }}</div>

        <div class="upsale-content my-4">

          <div class="d-flex flex-wrap gap-2">
            <div class="col-md-auto col-12 text-center">
              <div class="product-svg trunk-svg trunk-only mat-select-svg">

                <svg-image :img="productSvgSource" :is-html="true"/>
              </div>
            </div>
            <div
                class="col-md no_mobile col-12 d-flex align-items-center justify-content-md-start justify-content-center"
            >
              <div class="trunk-data">
                <div class="product-name" style="width: 100%">שטיח לתא מטען
                  {{ upsaleTrunk?.product?.productCategories?.nodes[0]?.matCategory?.matTitle || '' }}
                </div>
                <div class="cart_item_meta">
                  <div class="car_make" :key="carTitle">
                    {{ carTitle }}
                  </div>
                  <div class="meta">
                    <div class="meta-item" v-for="meta in carData.selected_meta">
                      {{ meta.label }}: {{ meta.value }}
                    </div>
                  </div>
                  <div class="car_year d-none">{{ carData?.car_year }}</div>
                  <template v-if="productCategory == 21">
                    <div class="mat_color">{{ `צבע שטיח: ${carData.mat_color?.label}` }}</div>
                    <div class="border_color">{{ `צבע המסגרת: ${carData.mat_border_color?.label}` }}</div>
                  </template>
                </div>
              </div>
            </div>
            <div class="col-md-auto no_mobile col-12 d-flex align-items-center justify-content-center">
              <div class="price">
                <div class="sale-price text--red">₪{{ discountedPrice }}</div>
                <del class="regular-price">₪{{ upsaleTrunk.trunk.price }}</del>
              </div>
            </div>
            <div class="col-12 mobile_only">
              <div class="d-flex">
                <div class="col">
                  <div class="trunk-data">
                    <div class="product-name" style="width: 100%">שטיח לתא מטען
                      {{ upsaleTrunk.product.productCategories.nodes[0].matCategory.matTitle }}
                    </div>
                    <div class="cart_item_meta">
                      <div class="car_make" :key="carTitle">
                        {{ carTitle }}
                      </div>

                      <div class="meta">
                        <div class="meta-item" v-for="meta in carData.selected_meta">
                          {{ meta.label }}: {{ meta.value }}
                        </div>
                      </div>
                      <template v-if="productCategory == 21">
                        <div class="mat_color">{{ `צבע שטיח: ${carData.mat_color?.label}` }}</div>
                        <div class="border_color">{{ `צבע המסגרת: ${carData.mat_border_color?.label}` }}</div>
                      </template>
                    </div>
                  </div>
                </div>
                <div class="col-auto d-flex align-items-center">
                  <div class="price">
                    <div class="sale-price text--red">₪{{ discountedPrice }}</div>
                    <del class="regular-price">₪{{ upsaleTrunk.trunk.price }}</del>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="upsale-actions px-0 px-xs-4">
          <div class="row">
            <div class="col">
              <a href="javascript:void(0)"
                 class="bt-cancel bg--grey text-center d-block p-2 pointer hover:bg-black hover:text-white"
                 @click="closeUpsalePop"
              >לא כרגע</a
              >
            </div>
            <div class="col">
              <a class="bt-add text-center d-block p-2" href="javascript:void(0)" @click="submitAddToCart(false)">הוסף
                לסל</a>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.trunk-upsale-modal .upsale-content {
  border: 1px solid;
  padding: 15px;
}

.trunk-upsale-modal .modal-dialog {
  max-width: 650px;
}

.trunk-upsale-modal .trunk-svg {
  border: 2px solid red;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  position: static !important;
  @media (max-width: 768px) {
    max-width: 170px;
    width: 170px;
    height: 170px;
  }
}

.trunk-upsale-modal .trunk-svg svg #row1,
.trunk-upsale-modal .trunk-svg svg #row2,
.trunk-upsale-modal .trunk-svg svg #row3 {
  display: none;
}

.trunk-upsale-modal .trunk-svg svg {
  transform: none !important;
  height: auto;
  width: 100px;
  max-height: 140px;
}

.bt-add {
  background-color: #46b449;
}

.bt-add:hover {
  background-color: black;
  color: #fff;
}
</style>
